import React, { useState, useEffect } from 'react'
import AdminNavigation from '../ReusableComponents/AdminNavigation';
import { Col, Container, Row, Stack, Tab, Table } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';




function AdminTickets() {
    const [selectedOption, setSelectedOption] = useState('');
    const [ticketData, setTicketData] = useState("");
    const [oTickets, setOtickets] = useState([]);
    const [ipTickets, setIPTickets] = useState([]);
    const [cTickets, setCTickets] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const api = "https://ha4tu3ysv5.execute-api.ap-south-1.amazonaws.com/getallticketsadmin"
        axios.post(api)
          .then(response => {
            const responseObject = JSON.parse(response.data.body);
            const filtered_items = responseObject.filter(item => item.instituteName && !item.instituteName.toLowerCase().startsWith('ttw'));
            setTicketData(filtered_items)
            const openTickets = filtered_items.filter(item => item.status === 'open');
            const inProgressTickets = filtered_items.filter(item => item.status === 'inprogress');
            const closedTickets = filtered_items.filter(item => item.status === 'close');
            setOtickets(openTickets);
            setIPTickets(inProgressTickets);
            setCTickets(closedTickets)
          })
          .catch(err => {
            console.log(err);
          });
    }, []);


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
      };

    const handleRespond = (data) => {
        navigate('/respond', { state: { data: data } })
    }
  return (
    <div>
        <AdminNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
            <div className='raiseTicketPage'>
                <select className='selectOption' value={selectedOption} onChange={handleChange}>
                    <option value="">Select Ticket Type</option>
                    <option value="open">Open Tickets</option>
                    <option value="inprogress">Processing Tickets</option>
                    <option value="close">Closed Tickets</option>
                </select>
            </div>
            {selectedOption === "" ?
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>Please select ticket type</h4>
            </div>
            :
            null}


            {selectedOption === "open" ?
            <div>
            {oTickets.length !== 0 ?
            <Table className="custom-table" bordered size='sm'>
                <thead>
                    <tr>
                        <th> </th>
                        <th>Institution Name</th>
                        <th>District</th>
                        <th>Principal Name</th>
                        <th>Phone Number</th>
                        <th>Issue Subject</th>
                        <th>Ticket Open Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {oTickets?.map((item, index) => {
                        return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{item.instituteName}</td>
                            <td>{item.district}</td>
                            <td>{item.principalName}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{item.subject}</td>
                            <td>{item.dateOfOpen}</td>
                            <td>
                                <button className="custom-button1" 
                                onClick={() => handleRespond(item)}
                                ><span style={{color:"White", textDecoration:"none"}}>Respond</span></button>
                            </td>
                        </tr>
                        )
                    })}
                    
                    {/* Add more rows as needed */}
                </tbody>
            </Table>
            :

            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                  <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no open tickets</h4>
              </div>
            }
            </div>
            :
            null}

            {selectedOption === "inprogress" ?
            <div>
            {ipTickets.length !== 0 ?
            <Table className="custom-table" bordered size='sm'>
                <thead>
                    <tr>
                        <th> </th>
                        <th>Institution Name</th>
                        <th>District</th>
                        <th>Principal Name</th>
                        <th>Phone Number</th>
                        <th>Issue Subject</th>
                        <th>Ticket Open Date</th>

                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {ipTickets?.map((item, index) => {
                        return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{item.instituteName}</td>
                            <td>{item.district}</td>
                            <td>{item.principalName}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{item.subject}</td>
                            <td>{item.dateOfOpen}</td>

                            <td>
                                <button className="custom-button1" 
                                onClick={() => handleRespond(item)}
                                ><span style={{color:"White", textDecoration:"none"}}>Respond</span></button>
                            </td>
                        </tr>
                        )
                    })}
                    
                    {/* Add more rows as needed */}
                </tbody>
            </Table>
            :
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                  <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no tickets in progress</h4>
              </div>
            }
            </div>
            :
            null}

            {selectedOption === "close" ?
            <div>
            {cTickets.length !== 0 ? 
            <Table className="custom-table" bordered size='sm'>
                <thead>
                    <tr>
                        <th> </th>
                        <th>Institution Name</th>
                        <th>District</th>
                        <th>Principal Name</th>
                        <th>Phone Number</th>
                        <th>Issue Subject</th>
                        <th>Ticket Open Date</th>
                        <th>Ticket Close Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {cTickets?.map((item, index) => {
                        return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{item.instituteName}</td>
                            <td>{item.district}</td>
                            <td>{item.principalName}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{item.subject}</td>
                            <td>{item.dateOfOpen}</td>
                            <td>{item.dateOfClose}</td>
                            <td>
                                <button className="custom-button1" 
                                onClick={() => handleRespond(item)}
                                ><span style={{color:"White", textDecoration:"none"}}>View</span></button>
                            </td>
                        </tr>
                        )
                    })}
                    
                    {/* Add more rows as needed */}
                </tbody>
            </Table>
            :
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                  <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no closed tickets</h4>
              </div>
            }
            </div>
            :
            null}

        </div>
        <Footer/>
    </div>
  )
}

export default AdminTickets;