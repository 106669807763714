import React, { useState } from 'react';
import Footer from "./Footer";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Input({type, id, name, label, placeholder, autofocus, value, onChange}) {

    return (
      <label className="custom-text">{label}
        <input
          autoFocus={autofocus}
          type={type} 
          id={id} 
          name={name} 
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          className="custom-input"/>
        
      </label>
    )
  }
  function Button({value, onClick}) {
    return (
      <button 
        onClick={onClick}
        className="custom-button">
        {value}
    </button>
    )
  }

function Login() {
  const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = () => {
      if(username === "admin2023" && password === "tvtcontractors"){
        navigate('/dashboard');
      }
      else{
        const api = "https://ws44o9yvv5.execute-api.ap-south-1.amazonaws.com/getInstitutionInfo"
        const data = {
          userName : username
        }
        axios.post(api, data)
          .then(response => {
            
            const responseObject = JSON.parse(response.data.body);
            if(password === responseObject.Password){
              // alert("login successfull");
              localStorage.setItem("id", responseObject.InstitutionId);
              localStorage.setItem("iname", responseObject.InstitutionName);
              localStorage.setItem("district", responseObject.DistrictName);
              localStorage.setItem("principal", responseObject.PrincipalName);
              localStorage.setItem("phone", responseObject.PhoneNumber)
              navigate('/home', { state: { data: responseObject } });
            }
            else{
              alert("Oops! Wrong user name or password !")
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      
    };

    return (
        
        <div className="custom-container">
                    <div className=" custom-box">
                    <h1 className="custom-heading">
                      <img src='./images/telangana.png' style={{height:"40px", width:"40px", marginRight:"8px"}}/>
                      
                      TGSWREIS</h1>

                        <h1 className="custom-heading">Log In</h1>
                        <div style={{textAlign:"start"}}>
                            <Input type="text" id="email" name="email" label="Username" placeholder="TXYZ123BZRT" value={username} onChange={handleUsernameChange} autofocus={true}/>
                            <Input type="password" id="password" name="password" label="Password" placeholder="••••••••••" value={password} onChange={handlePasswordChange}/>
                            <Button value="Submit" onClick={handleLogin} />
                        </div>
                        <hr />
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                          <img src='./images/applogo.png' style={{height:"60px", width:"60px", marginRight:"8px"}}/>
                          <p style={{fontWeight:"bold"}}>A Product of TGSWREIS </p>
                          <img src='./images/telangana.png' style={{height:"60px", width:"60px", marginRight:"8px"}}/>

                        </div>
                    </div>
                    <Footer/>
            </div>
    );
}

export default Login