import React, { useState, useEffect } from 'react'
import AdminNavigation from '../ReusableComponents/AdminNavigation';
import { Col, Container, Row, Stack, Tab, Table } from 'react-bootstrap';
import Footer from '../Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';




function RespondPage() {
  const location = useLocation();
  const ticketData = location && location.state && location.state.data;
  const [response, setResponse] = useState("");
  const [responseList, setResponseList] = useState([]);
const [report, setReport] = useState("");
const navigate = useNavigate();

  useEffect(() => {
    const ticketData = location && location.state && location.state.data;
    setResponseList(ticketData.response);
  }, []);

  const handleResponseChange = (e) => {
    setResponse(e.target.value)
  }

  const handleReportChange = (e) => {
    setReport(e.target.value)
  }

  const onSave = () => {
    if(response !== ""){
        setResponseList([...responseList, response])
        ticketData.response = [...responseList, response]
        setResponse("")


        const api = "https://fymmjobn9a.execute-api.ap-south-1.amazonaws.com/updateResponse";
        const data = {
            TicketId : ticketData.TicketId,
            response: [...responseList, response]
          }
          axios.post(api, data)
            .then(response => {
              const responseObject = JSON.parse(response.data.body);
            })
            .catch(err => {
              console.log(err);
            });
    }
    
  }

  const submitReport = () => {
    const api = "https://luec3hi9rg.execute-api.ap-south-1.amazonaws.com/updateSatisfactoryReport"
    const data = {
        TicketId : ticketData.TicketId,
        satisfactoryReport : report
    }
    axios.post(api, data)
            .then(response => {
              const responseObject = JSON.parse(response.data.body);
              alert("Your satisfactory report has been submitted successfully!")
              navigate('/admintickets')
            })
            .catch(err => {
              console.log(err);
            });
  }

  return (
    <div>
        <AdminNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
            <div style={{margin:"0px 0px 30px 0px", textAlign:"start", borderBottom: "4px solid #1273eb"}}>
                <h4 style={{fontWeight:"bold"}}>Ticket Raised By: {ticketData.instituteName}</h4>
            </div>
            <div className='respondContainer'>
                <div className='subjectHead'>
                    <h4 style={{fontWeight:"bold"}}>Subject: {ticketData.subject}</h4>
                </div>
                <div style={{padding:"10px", marginTop:"10px"}}>
                    <h6 style={{fontWeight:"bold"}}><b>Issue Key Word: {ticketData.keyWord}</b></h6>  
                </div>
                <div className='respondBody'>
                    <p>{ticketData.body}</p>
                </div>
                
                <div className='textAreaContainer'>
                    <h6 style={{fontWeight:"bold", marginTop:"24px"}}>Response:</h6>
                    {ticketData.response.map((item, index) => {
                        return(
                            <div className='responses'>
                                <b>Response {index + 1}: </b>{item}
                            </div>
                        )
                    })}
                    
                    {ticketData.satisfactoryReport === "" ?
                    <div>
                    <div>
                        <h6 style={{fontWeight:"bold", marginTop:"24px"}}>Submit new response:</h6>
                        <textarea 
                        className='textArea'
                        placeholder='Response to the issue'
                        value={response}
                        onChange={handleResponseChange}
                        />
                        <div style={{display:"flex", justifyContent:"end"}}>
                            <button className='custom-button1' onClick={onSave}>Submit Response</button>
                        </div>
                    </div>

                    <div>
                        <h6 style={{fontWeight:"bold", marginTop:"24px"}}>Submit satisfactory Report:</h6>
                        <p>By submitting this report, the issue will be considered closed</p>
                        <textarea 
                        className='textArea'
                        placeholder='The issue has been resolved and I am closing this ticket.'
                        value={report}
                        onChange={handleReportChange}
                        />
                        <div style={{display:"flex", justifyContent:"end"}}>
                            <button className='custom-button1' onClick={submitReport}>Submit Report</button>
                        </div>
                    </div>
                    </div>
                    :
                    <div className='responses'>
                                <b>Satisfactory Report:</b> {ticketData.satisfactoryReport}
                            </div>
                }
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default RespondPage