import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import InstituteInfo from "./components/PrincipalFlow/InstituteInfo";
import Tickets from "./components/PrincipalFlow/Tickets";
import CreateTicket from "./components/PrincipalFlow/CreateTicket";
import Dashboard from "./components/AdminFlow/Dashboard";
import AdminTickets from "./components/AdminFlow/AdminTickets";
import AddInstitution from "./components/AdminFlow/AddInstitution";
import Login from "./components/Login";
import RespondPage from "./components/AdminFlow/RespondPage";




import React from 'react';
// import { useNavigate } from 'react-router';
// import { useState, useEffect } from 'react';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/home" element={<InstituteInfo />} />
          <Route path="/principaltickets" element={<Tickets />} />
          <Route path="/createticket" element={<CreateTicket />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admintickets" element={<AdminTickets />} />
          <Route path="/addinstitute" element={<AddInstitution />} />
          <Route path="/respond" element={<RespondPage />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
