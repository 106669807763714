import React, {useState, useEffect} from 'react'
import AdminNavigation from '../ReusableComponents/AdminNavigation';
import { Col, Container, Row, Stack, Tab, Table } from 'react-bootstrap';
import Footer from '../Footer';
import axios from 'axios';


function Dashboard() {
  const [instituteData , setInstituteData] = useState();
  const [instCount, setInstCount] = useState("");
  const [ocount, setOcount] = useState("")
  const [pcount, setPcount] = useState("")
  const [ccount, setCcount] = useState("")

  // https://bkw1sed64k.execute-api.ap-south-1.amazonaws.com/getAllInstitutionsList
  useEffect(() => {
    
    const api = "https://bkw1sed64k.execute-api.ap-south-1.amazonaws.com/getAllInstitutionsList"
      
      axios.post(api)
        .then(response => {
          const responseObject = JSON.parse(response.data.body);
          const filtered_items = responseObject.items.filter(item => item.InstitutionName && !item.InstitutionName.toLowerCase().startsWith('ttw'));
          setInstCount(filtered_items.length)
          setInstituteData(filtered_items)
        })
        .catch(err => {
          console.log(err);
        });
          const api1 = "https://ha4tu3ysv5.execute-api.ap-south-1.amazonaws.com/getallticketsadmin"
          axios.post(api1)
            .then(response => {
              const responseObject = JSON.parse(response.data.body);
              const filtered_items = responseObject.filter(item => item.instituteName && !item.instituteName.toLowerCase().startsWith('ttw'));
              const openTickets = filtered_items.filter(item => item.status === 'open');
              const inProgressTickets = filtered_items.filter(item => item.status === 'inprogress');
              const closedTickets = filtered_items.filter(item => item.status === 'close');
              setOcount(openTickets.length);
              setPcount(inProgressTickets.length);
              setCcount(closedTickets.length)
            })
            .catch(err => {
              console.log(err);
            });

}, []);

  return (
    <div>
        <AdminNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
        <div className='countCardsDashboard'>
              <div className='cardDashboard' style={{backgroundColor:""}}>
                <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='countsDashboard'>{instCount}</p>
                        <img src='./images/schoolIcon.png' style={{height:"35px", width:"35px"}}alt='person'/>
                    </div>
                      <span style={{fontSize:"large"}}>Total Institutions</span>
                    
                </div>
                <div className='cardDashboard' style={{backgroundColor:"#e0574d", border:"1px solid #e0574d"}}>
                <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='countsDashboard'>{ocount}</p>
                        <img src='./images/camera.png' style={{height:"35px", width:"35px"}}alt='person'/>
                    </div>
                      <span style={{fontSize:"large"}}>Tickets Open</span>
                    
                </div>
                <div className='cardDashboard' style={{backgroundColor:"#9d9d9d", border:"1px solid #9d9d9d"}}>
                    <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='countsDashboard'>{pcount}</p>
                        <img src='./images/Danger.svg' style={{height:"35px", width:"35px"}} alt='person'/>
                    </div>
                      <span style={{fontSize:"large"}}>Tickets In Progress</span>
                    
                </div>
                <div className='cardDashboard' style={{border:"1px solid #53c759", backgroundColor:"#53c759"}}>
                <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='countsDashboard'>{ccount}</p>
                        <img src='./images/resolved.png' style={{height:"35px", width:"35px"}} alt='person'/>
                    </div>
                      <span style={{fontSize:"large"}}>Tickets Closed</span>
                    
                </div>
            </div>
            <div style={{marginTop:"24px"}}>
              <div style={{backgroundColor:"white", border:"1px solid #e1e5e7", borderRadius:"8px 8px 0px 0px"}}>
                <h4 style={{padding:"12px", fontWeight:"bolder", textAlign:"start"}}>Institution Information</h4>
              </div>
            <Table className="custom-table" bordered size='sm'>
                <thead>
                    <tr>
                        <th> </th>
                        <th>Institution Name</th>
                        <th>Region</th>
                        <th>District</th>
                        <th>Cameras</th>
                        <th>Installation Status</th>
                    </tr>
                </thead>

                <tbody>
                  {instituteData?.map((item, index) => {
                    return(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.InstitutionName}</td>
                        <td>{item.RegionName}</td>
                        <td>{item.DistrictName}</td>
                        <td>{item.Cameras}</td>
                        <td>
                          {item.InstallationVerified === "False" ? 
                          <button style={{backgroundColor:"#9d9d9d", color:"white", borderRadius:"20px", border:"1px solid #9d9d9d"}}>Pending</button>
                            
                            :
                            <button style={{backgroundColor:"#53c759", color:"white", borderRadius:"20px", border:"1px solid #53c759"}}>Complete</button>
                    }
                        
                            </td>
                    </tr>
                    )
                  })}
                  
                    {/* Add more rows as needed */}
                </tbody>
            </Table>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Dashboard