import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";


const AdminNavigation = ({title}) => {
  return (
    <div className='navbar'>
      <div>
        <h3  className='title'>
        <img src='./images/applogo.png' style={{height:"40px", width:"40px", marginRight:"8px"}}/>

        TGSWREIS
        </h3>
      </div>
      <div className='navItems'>
        <p className='item'>
          <img src='./images/dashboard.png' style={{height:"16px", width:"16px", marginRight:"8px"}} alt='person'/>
          <NavLink to="/dashboard" style={{color:"white", textDecoration:"none"}}>Dashboard</NavLink>
        </p>
        <p className='item'>
        <img src='./images/ticketsWhite.png' style={{height:"16px", width:"16px", marginRight:"8px"}} alt='person'/>
        <NavLink to="/admintickets" style={{color:"white", textDecoration:"none"}}>Tickets</NavLink></p>
        <p className='item'>
        <img src='./images/schoolWhite.png' style={{height:"26px", width:"26px", marginRight:"8px"}} alt='person'/>
        <NavLink to="/addinstitute" style={{color:"white", textDecoration:"none"}}>Add Institution</NavLink></p>
          <p className='item'>
          <img src='./images/logoutWhite.png' style={{height:"16px", width:"16px", marginRight:"8px"}} alt='person'/>
          <NavLink to="/" style={{color:"white", textDecoration:"none"}}>Logout</NavLink>
          </p>
      </div>
    </div>
  )
}

export default AdminNavigation
