import React, {useState, useEffect} from 'react'
import PrincipalNavigation from '../ReusableComponents/PrincipalNavigation';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import { useLocation } from 'react-router-dom';
import axios from 'axios';




const InstituteInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [verified, setVerified] = useState("False");
  const [instituteData, setInstituteData] = useState();
  const [oTickets, setOtickets] = useState([]);
  const [ipTickets, setIPTickets] = useState([]);
  const [cTickets, setCTickets] = useState([]);
  useEffect(() => {
    
    const api = "https://ws44o9yvv5.execute-api.ap-south-1.amazonaws.com/getInstitutionInfo"
      const data = {
        userName : localStorage.getItem("id")
      }
      axios.post(api, data)
        .then(response => {
          const responseObject = JSON.parse(response.data.body);
          setInstituteData(responseObject)
        })
        .catch(err => {
          console.log(err);
        });
        // ====================================================

        const api1 = "https://ygoeahm6k8.execute-api.ap-south-1.amazonaws.com/getAllTicketsClient"
      const data1 = {
        endingString : localStorage.getItem("id")
      }
      axios.post(api1, data1)
        .then(response => {
          const responseObject = JSON.parse(response.data.body);
          // setTicketData(responseObject)
          const openTickets = responseObject.filter(item => item.status === 'open');
          const inProgressTickets = responseObject.filter(item => item.status === 'inprogress');
          const closedTickets = responseObject.filter(item => item.status === 'close');
          setOtickets(openTickets);
          setIPTickets(inProgressTickets);
          setCTickets(closedTickets)
        })
        .catch(err => {
          console.log(err);
        });

}, []);

  const instituteInfoReceived = location && location.state && location.state.data;

  const handleVerified = () => {
    setVerified("True");
    const api = "https://cukfp840jf.execute-api.ap-south-1.amazonaws.com/updateInstallationStatus"
    
    const data = {
      InstitutionId: instituteData?.UserName,
      InstallationVerified: "True",
    }

    console.log(data)
    axios.post(api, data)
      .then(response => {
        const responseObject = JSON.parse(response.data.body);
      })
      .catch(err => {
        console.log(err);
      });
  }
  return (
    <div>
        <PrincipalNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
          <div className='raiseTicket'>
            {
              instituteData?.InstallationVerified === "False" &&  verified === "False" ? 
                  <button className='raiseBtn' onClick={handleVerified}> 
                    Click to Verify Installation
                  </button>
                  :
                  <button className='verifiedBtn' > 
                  <img style={{height:"30px", width:"30px"}} src="./images/checked.png" />
                    Installation Verified
                  </button>

            }
            
            <button className='raiseBtn' ><img style={{height:"20px", width:"20px"}} src='./images/add.png' alt='add'/> 
            <NavLink to="/createticket" style={{color:"white", textDecoration:"none"}}>Raise New Ticket</NavLink></button>
          </div>
            <div className='schoolInfo'>
              <div className='schoolImage'>
                <img style={{height:"200px", width:"200px"}} src='./images/school.jpg' alt='schoool'/>
              </div>
              <div className='information'>
                  <div>
                    <h2>Name of institutuon: {instituteData?.InstitutionName}</h2>
                  </div>
                  <div>
                    <p className='paraInfo'>Region Name: {instituteData?.RegionName}</p>
                  </div>
                  <div>
                    <p className='paraInfo'>District Name: {instituteData?.DistrictName}</p>
                  </div>
                  <div className='personalDetails'>
                    <div style={{display:"flex", alignItems:"center"}}>
                      <img className='iconImage' style={{height:"40px"}} src='./images/Groupperson.svg' alt='person'/>
                      <p className='paraInfoSmall'>{instituteData?.PrincipalName}</p>
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                      <img className='iconImage' style={{height:"40px"}} src='./images/Telephone.svg' alt='phone'/>
                      <p className='paraInfoSmall'>{instituteData?.PhoneNumber}</p>
                    </div>
                </div>
              </div>
              
            </div>
            <div className='countCards'>
                <div className='card1'>
                <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='counts'>{instituteData?.Cameras}</p>
                        <img src='./images/camera.png' style={{height:"40px", width:"40px"}}alt='person'/>
                    </div>
                      <span style={{fontSize:"larger"}}>Total Cameras Installed</span>
                    
                </div>
                <div className='card2'>
                    <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='counts'>{oTickets?.length + ipTickets?.length + cTickets?.length}</p>
                        <img src='./images/Danger.svg' style={{height:"40px", width:"40px"}} alt='person'/>
                    </div>
                      <span style={{fontSize:"larger"}}>Total Tickets Raised</span>
                    
                </div>
                <div className='card3'>
                <div style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <p className='counts'>{cTickets.length}</p>
                        <img src='./images/resolved.png' style={{height:"40px", width:"40px"}} alt='person'/>
                    </div>
                      <span style={{fontSize:"larger"}}>Total Tickets Resolved</span>
                    
                </div>
            </div>
        </div>
        <Footer />
    </div>
    
  )
}
export default InstituteInfo;