import React, { useState, useEffect } from 'react'
import PrincipalNavigation from '../ReusableComponents/PrincipalNavigation';
import { Accordion } from 'react-bootstrap-accordion'
import 'react-bootstrap-accordion/dist/index.css';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import axios from 'axios';




function Tickets() {
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();
    const [ticketData, setTicketData] = useState("");
    const [oTickets, setOtickets] = useState([]);
    const [ipTickets, setIPTickets] = useState([]);
    const [cTickets, setCTickets] = useState([]);
   


    // endingString
    useEffect(() => {
      const api = "https://ygoeahm6k8.execute-api.ap-south-1.amazonaws.com/getAllTicketsClient"
      const data = {
        endingString : localStorage.getItem("id")
      }
      axios.post(api, data)
        .then(response => {
          const responseObject = JSON.parse(response.data.body);
          const filtered_items = responseObject.filter(item => item.instituteName && !item.instituteName.toLowerCase().startsWith('ttw'));
          setTicketData(filtered_items)
          const openTickets = filtered_items.filter(item => item.status === 'open');
          const inProgressTickets = filtered_items.filter(item => item.status === 'inprogress');
          const closedTickets = filtered_items.filter(item => item.status === 'close');
          setOtickets(openTickets);
          setIPTickets(inProgressTickets);
          setCTickets(closedTickets)
        })
        .catch(err => {
          console.log(err);
        });
    }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div>
        <PrincipalNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
          <div className='raiseTicketPage'>
            <select className='selectOption' value={selectedOption} onChange={handleChange}>
                  <option value="">Select Ticket Type</option>
                  <option value="open">Open Tickets</option>
                  <option value="inprogress">Processing Tickets</option>
                  <option value="close">Closed Tickets</option>
              </select>
            <button className='raiseBtn'><img style={{height:"20px", width:"20px"}} src='./images/add.png' alt='add'/> 
            <NavLink to="/createticket" style={{color:"white", textDecoration:"none"}}>Raise New Ticket</NavLink></button>
          </div>
          {selectedOption !== "" ? 
          <div>
            {selectedOption === "open" ? 
            <div>
              {/* <h4>Tickets Created</h4> */}
              {oTickets?.length !== 0 ? 
              <div>
              {oTickets?.map((item) => {
                return(
                  <Accordion title={item.subject} style={{width:"100px"}}>
                    <div style={{height:"200px", overflowY:"scroll", textAlign:"start"}}>
                        {item.body}
                        </div>
                      </Accordion>
                )
              })} 
              </div>
              :
              <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                  <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no open tickets</h4>
              </div>
              
            }
              </div>
              :
              <div>
              {selectedOption === "inprogress" ? 
              <div>
              {ipTickets?.length !== 0 ?
                <div>
                  {/* <h4>Tickets work in progress</h4> */}
                  {ipTickets.map((item) => {
                    return(
                      <Accordion title={item.subject} style={{width:"100px"}}>
                        <div style={{height:"300px", overflowY:"scroll", textAlign:"start"}}>
                          {item.body}
                          
                          <div style={{marginTop:"20px"}}>
                          <h6>Response:</h6>
                            {item.response.map((resp) => {
                              return (
                                
                                <div className='responses'>
                                {resp} 
                                </div>
                              )
                            })}
                          </div>
                          <div style={{marginTop:"20px"}}>
                          <h6>Satisfactory Report:</h6>
                            <div className='responses'>
                                {item.satisfactoryReport} 
                                </div>
                          </div>
                        </div>
                      </Accordion>
                    )
                  })}
                  </div>
                  :
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                      <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no tickets in progress</h4>
                  </div>
              }
              </div>
                :
                <div>
                  {/* <h4>Tickets closed</h4> */}
                  {cTickets?.length !== 0 ?
                  <div>
                  {cTickets.map((item) => {
                    return(
                      <Accordion title={item.subject} style={{width:"100px"}}>
                        <div style={{height:"300px", overflowY:"scroll", textAlign:"start"}}>
                          {item.body}
                          
                          <div style={{marginTop:"20px"}}>
                          <h6>Response:</h6>
                            {item.response.map((resp) => {
                              return (
                                <div className='responses'>
                                    {resp} 
                                </div>
                              )
                            })}
                          </div>
                          <div style={{marginTop:"20px"}}>
                          <h6>Satisfactory Report:</h6>
                            <div className='responses'>
                                {item.satisfactoryReport} 
                                </div>
                          </div>
                        </div>
                      </Accordion>
                    )
                  })}
                  </div>
                  :
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                      <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>There are no closed tickets</h4>
                  </div>
                  }
                  </div>
            }
          </div>
          }
              

          </div>
          :
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
              <h4><img src="./images/info.png" style={{height:"30px", marginRight:"20px"}}/>Please select Ticket Type to display</h4>
          </div>
          
          
          }
        
        </div>
        <Footer />
    </div>
  )
}

export default Tickets