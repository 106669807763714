import React, { useEffect, useState } from 'react';
import PrincipalNavigation from '../ReusableComponents/PrincipalNavigation';
import Footer from '../Footer';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


function CreateTicket() {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [keyWord, setKeyWord] = useState("")
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate('/principaltickets')
    
  }
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleKeyWordChange = (event) => {
    setKeyWord(event.target.value)
  }

  const createTicket = () => {
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(Math.random() * 10000); // Random number between 0 and 9999
    const ticketId = `TICKET${timestamp}${randomNum}${localStorage.getItem("id")}`; // Combine timestamp and random number
    const currentDate = new Date();
    const dateString = currentDate.toISOString().split('T')[0];
    const data = {
      TicketId: ticketId,
      body: description,
      dateOfClose: "",
      dateOfOpen: dateString,
      InstitutionId: localStorage.getItem("id"),
      response: [],
      satisfactoryReport: "",
      status: "open",
      subject: subject,
      keyWord: keyWord,
      instituteName : localStorage.getItem("iname"),
      district: localStorage.getItem("district"),
      phoneNumber: localStorage.getItem("phone"),
      principalName: localStorage.getItem("principal")
    }

    const api = "https://os0376qvb2.execute-api.ap-south-1.amazonaws.com/createTicket"
    axios.post(api, data)
    .then(response => {
      const responseObject = JSON.parse(response.data.body);
      setShow(true)
    })
    .catch(err => {
      console.log(err);
    });
  }

  return (
    <div>
        <PrincipalNavigation title={"TVT Contractors"}/>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Institution Info Registered Successfully !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Ticket raised successfully, you can view them in the tickets window !</b>
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={handleClose}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
        <div className='mainBody'>
          <div style={{margin:"0px 0px 30px 0px", textAlign:"start", borderBottom: "2px solid #1273eb"}}>
            <h4>Create New Ticket</h4>
          </div>
          <div className='createTicket' >
            <div className='subjectContent'>
              <div className='item1'>
                <h6>Subject of the Issue:</h6>
                <input name="postTitle"
                value={subject}
                onChange={handleSubjectChange}
                 className='subjectInput' placeholder='Camera wire burnt' />
              </div>
              <div className='item2'>
                <h6>Type of Issue:</h6>
                <select className='subjectInput' value={keyWord} onChange={handleKeyWordChange} >
                  <option className='options' value={""}>Select the type of issue</option>
                  <option className='options' value={"Camera"}>Camera</option>
                  <option className='options' value={"Wire"}>Wire</option>
                  <option className='options' value={"Adapter"}>Adapter</option>
                  <option className='options' value={"others"}>others</option>
                </select>
              </div>
            </div>
            <div className='messageContent'>
              <h6>Give a detailed description of the issue:</h6>
              <textarea
              className='subjectInput'
              style={{minHeight:"100px"}}
              onChange={handleDescriptionChange}
              value={description}
                name="postContent"
                placeholder="Detailed description on the issue with the camera"
              />
            </div>
            <hr />
            <div className='btnContainer'>
              <button className='cancelBtn' type="reset">Cancel Ticket</button>
              <button className="submitBtn" onClick={createTicket}>Submit Issue</button>
            </div>
            
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default CreateTicket