import React, {useState, useEffect} from 'react'
import AdminNavigation from '../ReusableComponents/AdminNavigation';
import Footer from '../Footer';
import { v4 as uuidv4 } from 'uuid';
// import { createHash } from 'hash.js';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';



function AddInstitution() {
  const [institutionName, setInstitutionName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [principalName, setPrincipalName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [insititutionId, setInstitutionId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [totalCameras, setTotalCameras] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  
  const handleInstitutionNameChange = (e) => {
    setInstitutionName(e.target.value);
  };

  const handleTotalCamerasChange = (e) => {
    setTotalCameras(e.target.value)
  }

  const handleRegionNameChange = (e) => {
    setRegionName(e.target.value);
  };

  const handleDistrictNameChange = (e) => {
    setDistrictName(e.target.value);
  };

  const handlePrincipalNameChange = (e) => {
    setPrincipalName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const createNewInstitution = () => {

    const api = "https://sp99cmpg1l.execute-api.ap-south-1.amazonaws.com/putInstitutionInfo"
    
    const data = {
      InstitutionId: "",
      Cameras: totalCameras.toString(),
      DistrictName:districtName,
      InstallationVerified: "False",
      InstitutionName:institutionName,
      Password: "tvt@12345",
      PhoneNumber: phoneNumber.toString(),
      PrincipalName: principalName,
      RegionName:regionName,
      UserName: "TVT000001"
    }

    axios.post(api, data)
      .then(response => {
        const responseObject = JSON.parse(response.data.body);
        setUserName(responseObject.userName);
        setPassword(responseObject.password);
        setShow(true)
      })
      .catch(err => {
        console.log(err);
      });
  }
  const handleClose = () => {
    setShow(false);
    navigate('/dashboard')
    
  }


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Institution Info Registered Successfully !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>User Name: {userName}</b>
          <hr/>
          <b>Password: {password}</b>
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={handleClose}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
        <AdminNavigation title={"TVT Contractors"}/>
        <div className='mainBody'>
            <div style={{margin:"0px 0px 30px 0px", textAlign:"start", borderBottom: "4px solid #1273eb"}}>
                <h4 style={{fontWeight:"bold"}}>Add Insititution Details</h4>
            </div>
            <div className='institutionContainer'>
              <div style={{display:"flex", gap:"30px", alignItems:"center"}}>
                <h4>Institution Name:</h4>
                <input
                  style={{width:"60vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                placeholder='Name of the institution'
                value={institutionName}
                onChange={handleInstitutionNameChange}
                />
              </div>
              <div style={{display:"flex", flexDirection:"row",marginTop:"20px", justifyContent:"space-between"}}>
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                    <p>Region Name</p>
                    <input 
                      style={{width:"35vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                      type="text"
                      value={regionName}
                      onChange={handleRegionNameChange}
                    placeholder='Region Name'/>
                </div>
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                    <p>District Name</p>
                    <input 
                      style={{width:"35vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                      onChange={handleDistrictNameChange}
                      type="text"
                      value ={districtName}
                    placeholder='District Name'/>
                </div>

              </div>
              <div style={{display:"flex", flexDirection:"row",marginTop:"20px", justifyContent:"space-between"}}>
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                    <p>Principal Name</p>
                    <input 
                      style={{width:"35vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                      onChange={handlePrincipalNameChange}
                      type="text"
                      value={principalName}
                    placeholder='Principal Name'/>
                </div>
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                    <p>Contact Number</p>
                    <input 
                      style={{width:"35vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                      onChange={handlePhoneNumberChange}
                      type="number"
                      value={phoneNumber}
                    placeholder='Phone Number'/>
                </div>

              </div>
              <div style={{display:"flex", flexDirection:"row",marginTop:"20px", justifyContent:"space-between"}}>
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                    <p>No. of Cameras Installed</p>
                    <input 
                      style={{width:"35vw", padding:"12px", border:"1px solid #e1e5e7", borderRadius:"8px"}}
                      onChange={handleTotalCamerasChange}
                      value={totalCameras}
                      type="number"
                    placeholder='0'/>
                </div>

              </div>
              <div style={{display:"flex", flexDirection:"row",marginTop:"20px", justifyContent:"end", gap:"20px"}}>
                        <button className='custom-button1' style={{backgroundColor:"black"}}>Cancel</button>
                        
                        <button className='custom-button1' onClick={createNewInstitution}>Submit</button>
                        

              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default AddInstitution