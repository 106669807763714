import React from 'react'

function Footer() {
  return (
    <div className='footer'>
                <div>
                <img src='./images/applogo.png' style={{height:"30px", width:"30px", marginRight:"8px"}}/>

                  Copyrights 2023 @ TGSWREIS {"   "}
                <img src='./images/telangana.png' style={{height:"30px", width:"30px", marginRight:"8px"}}/>
                </div>
                

                
                <div>Managed by TVT CONTRACTORS LLP</div>
    </div>
  )
}

export default Footer